.break-all-word {
    overflow: hidden;
    word-break: break-all;
}

@media (min-width: 576px) {
    .nav-user-menu .user-image {
        float: none;
        line-height: 10px;
        margin-top: -8px;
    }
}

textarea {
    min-height: calc(2.25rem + 2px);
}

.freeze-ui {
    position: fixed !important;
}

.nav-user-menu .user-image {
    border-radius: 50%;
    float: left;
    height: 2rem;
    margin-top: -4px;
    width: 2rem;
}

.form-group.required .col-form-label:after {
    color: #d00;
    content: '*';
    position: absolute;
    margin-left: 3px;
}

/* Sweet alert */
.swal2-title {
    display: inline-block !important;
}

body.swal2-height-auto {
    height: inherit !important;
}

.swal2-popup.swal2-toast {
    padding: 10px 12px !important;
    display: block !important;
}

.swal2-popup.swal2-toast .swal2-header,
.swal2-popup.swal2-toast .swal2-title,
.swal2-popup.swal2-toast .swal2-content {
    text-align: left !important;
    margin: 0 !important;
}

.swal2-popup.swal2-toast .swal2-title {
    margin-bottom: 4px !important;
}

hr {
    margin-top: 0.9rem !important;
    margin-bottom: 0.65rem !important;
}

div.wrapper,
div.content-wrapper,
div.sidebar {
    min-height: 100vh;
}

.shadow-default {
    box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.12);
}

.overflow-inherit {
    overflow: inherit;
}

.border-radius-default {
    border-radius: 0.25rem !important;
}

/* input readonly but not disabled */
.form-control.not-disabled[readonly] {
    background-color: #fff !important;
}

/* Fullcalendar */
full-calendar .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0.5rem !important;
}

full-calendar .fc-toolbar .fc-button {
    padding: 0.25rem 0.5rem !important;
    font-size: 0.875rem !important;
}

full-calendar .fc-toolbar .fc-button span {
    font-size: 0.875rem !important;
}

.fc-event {
    cursor: pointer !important;
}

/* ng-select */
.ng-select .ng-select-container {
    min-height: 31px !important;
}

.ng-select.ng-select-single .ng-select-container {
    height: 31px !important;
}

.input-group-append .ng-select .ng-select-container {
    border-radius: 0 4px 4px 0 !important;
}

.dropdown-menu {
    height: auto!important;
}

@media (min-width: 1400px) {
    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 1200px;
    }
}

ng-select.form-control{
    padding: initial !important;
}

.form-control .ng-select-container {
    position: initial !important;
    border: 0px;
    background: transparent !important;
    min-height: initial !important;
    height: inherit !important;
}

.form-control ng-value-container {
    padding-top: 0px !important;
}
